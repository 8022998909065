const states = [
  { id: 1, state: "lagos" },
  { id: 2, state: "abuja" },
  { id: 3, state: "rivers" },
  { id: 4, state: "oyo" },
  { id: 5, state: "kaduna" },
  { id: 6, state: "ogun" },
  { id: 7, state: "osun" },
  { id: 8, state: "ekiti" },
  { id: 9, state: "ondo" },
  { id: 10, state: "edo" },
  { id: 11, state: "kwara" },
  { id: 12, state: "abia" },
  { id: 13, state: "imo" },
  { id: 14, state: "enugu" },
  { id: 15, state: "anambra" },
  { id: 16, state: "ebonyi" },
  { id: 17, state: "imo" },
  { id: 18, state: "delta" },
  { id: 19, state: "bayelsa" },
  { id: 20, state: "rivers" },
  { id: 21, state: "cross-river" },
  { id: 22, state: "akwa-ibom" },
  { id: 23, state: "kogi" },
  { id: 24, state: "benue" },
  { id: 25, state: "kano" },
  { id: 26, state: "katsina" },
  { id: 27, state: "kebbi" },
  { id: 28, state: "bauchi" },
  { id: 29, state: "sokoto" },
  { id: 30, state: "nassarawa" },
  { id: 31, state: "zamfara" },
  { id: 32, state: "gombe" },
  { id: 33, state: "yobe" },
  { id: 34, state: "taraba" },
  { id: 35, state: "plateau" },
  { id: 36, state: "borno" },
];

export default states;
